$COL1: #f2f2f2; //
$COLFLAT1: #f2f2f2; //
$COL2: #4597e0; //
$COLFLAT2: #4597e0; //
$COL3: #00b2b2; //
$COLFLAT3: #00b2b2; //
$COL4: #333333; //
$COLFLAT4: #333333; //
$COL5: #f2f2f2; //
$COLFLAT5: #f2f2f2; //
$COL6: #848484; //
$COLFLAT6: #848484; //
$COL7: #333333; //
$COLFLAT7: #333333; //
$COL8: #00b2b2; //
$COLFLAT8: #00b2b2; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #333333; //
$COLFLAT11: #333333; //
$COL12: #333333; //
$COLFLAT12: #333333; //
$COL13: #333333; //
$COLFLAT13: #333333; //
$COL14: #333333; //
$COLFLAT14: #333333; //
$COL15: #333333; //
$COLFLAT15: #333333; //
$COL16: #333333; //
$COLFLAT16: #333333; //
$COL17: #333333; //
$COLFLAT17: #333333; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #fefefe; //
$COLFLAT19: #fefefe; //
$COL20: #ffffff; //white
$COLFLAT20: #ffffff; //white
$COL21: rgba(8,8,8,0.62); //Dark transparent
$COLFLAT21: #363636; //Dark transparent
$COL22: #1b1b1b; //DARKER
$COLFLAT22: #1b1b1b; //DARKER
$COL23: rgba(0,178,178,0.651); //form bg
$COLFLAT23: #2da1a1; //form bg
$COL24: rgba(255,255,255,0.22); //white transparent
$COLFLAT24: #9c9c9c; //white transparent
$COL25: rgba(0,0,0,0); //transparent
$COLFLAT25: #808080; //transparent
$COL26: #848484; //
$COLFLAT26: #848484; //
$COL27: #e8e8e8; //
$COLFLAT27: #e8e8e8; //
$COL28: rgba(0,0,0,0); //
$COLFLAT28: #808080; //
$COL29: #ffffff; //
$COLFLAT29: #ffffff; //
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
